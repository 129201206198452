    @import "../../styles/variables.scss";
    .vector {
        user-select: none;
        .vector-wrapper {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: center;
            .vector-cell {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 1em;
                flex: 1;
                .swiper-wrapper {
                    width: 90vw;
                    max-width: 800px;
                }
                img {
                    width: 100%;
                    // max-width: 1000px;
                    border-radius: 20px;
                    // flex: 1;
                }
                .description {
                    margin-left: 20px;
                    margin-right: 20px;
                    flex: 1;
                    // max-width: 500px;
                    // width: 100%;
                }
            }
            p {
                font-size: medium;
                font-weight: 700;
                font-family: $font-main;
                flex: 1;
                text-align: left;
                width: 100%;
            }
            @media (max-width:1000px) {
                .vector-cell {
                    flex-direction: column;
                    justify-content: space-between;
                    p {
                        flex: 1;
                        text-align: center;
                    }
                }
            }
        }
    }