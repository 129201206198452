@import "./variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Fira+Mono:wght@400;500;700&display=swap');
#root {
    background: $color-smoke;
    color: $color-darkgray;
    font-size: 20px;
    display: block;
    margin: 0;
    padding: 0;
}

* {
    font-family: $font, sans-serif;
}

body {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

// p {
//     font-size: $br-3;
// }
.capitalize {
    text-transform: capitalize;
}

.cursor {
    cursor: pointer;
}

.main-container {
    max-width: 1700px;
    padding: 0 2vw;
    width: calc(100% - 4vw);
}