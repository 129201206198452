    @import "../../styles/variables.scss";
    .teachers {
        // background-color: #F0F0F0;
        .teachers-wrapper {
            width: 100%;
            margin-bottom: 100px;
            .teachers-h1 {
                text-align: center;
                font-size: 400%;
                font-weight: 700;
                @media (max-width: 450px) {
                    font-size: 300%;
                }
                margin: 0;
            }
            .teacher-topic {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                align-items: top;
                margin-bottom: 1em;
                flex: 2;
                .topic-photo {
                    // width: calc(96vw - 2 * $br-sm);
                    max-width: 600px;
                    min-width: 300px;
                    text-align: center;
                    margin-top: 25px;
                    .photo-img {
                        width: calc(96vw - 2 * $br-sm);
                        max-width: 600px;
                        min-width: 300px;
                    }
                    .photo-h1 {
                        color: white;
                        margin-top: -100px;
                        margin-bottom: 100px;
                        line-height: 80%;
                        font-family: $font-main;
                        @media (max-width: 500px) {
                            font-size: 160%;
                            margin-top: -70px;
                            margin-bottom: 70px;
                        }
                    }
                }
                .topic-txt {
                    .topic-p {
                        font-family: $font-main;
                        font-weight: 600;
                    }
                    width: calc(96vw - 2 * $br-sm);
                    max-width: 600px;
                    margin-left: 3vw;
                    margin-right: 3vw;
                    min-width: 300px;
                }
            }
            .teacher-achives {
                display: flex;
                justify-content: center;
                .teacher-sert-img {
                    width: 5em;
                    @media (max-width: 1000px) {
                        width: 20vw;
                        max-width: 5em; // min-width: 300px;
                        margin: 0 1vw;
                    }
                }
            }
            .teacher-dances {
                text-align: center;
                .teacher-dances-list {
                    // display: flex;
                    // justify-content: center;
                    background-color: $color-white;
                    width: calc(96vw - 2 * $br-sm);
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 5%;
                    border-radius: $br-md;
                    padding: $br-sm;
                    font-family: $font-main;
                    font-weight: 600;
                }
            }
        }
    }