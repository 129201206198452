@import "../../styles/variables.scss";
.logo {
    .logo-wrapper {
        display: flex;
        flex-direction: row;
        width: calc(100% - 4vw);
        user-select: none;
        align-items: center;
        background: $color-normal-pink;
        padding: 2vw;
        .logo-image {
            flex: 1;
            .image {
                height: 20vw;
                width: auto;
            }
        }
        .logo-title {
            flex: 3;
            font-size: 12vw;
            margin: 0;
            line-height: 80%;
            color: $color-white;
        }
        border-radius: $br-md;
    }
}