@import "../../styles/variables.scss";
.navbar {
    width: 100%;
    user-select: none;
    text-transform: lowercase;
    // font-weight: 300;
    .navbar-wrapper {
        transition: 1s ease-in-out;
        display: flex;
        flex-direction: row;
        width: calc(100% - 2vw);
        // display: flex;
        height: 73px;
        a {
            color: $color-white
        }
        justify-content: space-between;
        padding-left: 1vw;
        padding-right: 1vw;
        @media (max-width: 1000px) {
            padding-right: 5px;
            width: calc(100% - 10px);
            padding-left: 5px;
        }
        // margin-top: 15px;
        background-color: $color-pink;
        border-radius: $br-md;
        // padding-top: 8px;
        // padding-bottom: 8px;
        .navbar-logo {
            display: flex;
            align-items: center;
            // justify-content: space-around;
            width: fit-content;
            margin-right: 0.6em; // width: 10%;
            // flex: 1;
            .navbar-logo-img {
                margin-left: 0.3em;
                // width: 200px;
                max-width: 20vw;
                height: 70%;
                margin-right: 0;
                img {
                    max-width: 20vw;
                }
                cursor: pointer;
            }
        }
        .navbar-topics {
            display: flex;
            justify-content: space-between;
            // width: 70%;
            // width: 60%;
            align-items: center;
            color: $color-white;
            .navbar-topics-p {
                margin-right: 10px;
                margin-left: 10px;
                font-weight: 700;
                font-family: $font-main;
                font-size: medium;
                cursor: pointer;
            }
        }
        @media (max-width: 1000px) {
            .navbar-topics {
                display: none;
            }
        }
        .navbar-links {
            width: 30%;
            min-width: 200px;
            @media (max-width: 1000px) {
                width: 80%;
            }
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: $color-white;
            // padding-left: 5%;
            // padding-right: 5%;
            .navbar-links-tg,
            .navbar-links-wa {
                cursor: pointer;
                padding-left: 20px;
                padding-right: 20px;
                margin-right: 5px;
                border-radius: 15px;
                height: 32px;
                width: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #27a7e7;
                .navbar-links-tg-img,
                .navbar-links-wa-img {
                    height: 60%;
                    width: auto;
                    @media (max-width: 450px) {
                        height: 60%;
                    }
                }
            }
            .navbar-links-wa {
                background-color: #40c351;
            }
            .navbar-links-call {
                cursor: pointer;
                padding-left: 15px;
                padding-right: 15px;
                border-radius: $br-md;
                @media (max-width: 450px) {
                    padding-left: 10px;
                    padding-right: 10px;
                    margin-bottom: 5px;
                    margin-top: 5px;
                }
                border: 1px solid white;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $color-dim;
                .navbar-links-call-p {
                    @media (max-width: 450px) {
                        font-size: small;
                    }
                    a {
                        font-size: medium;
                        font-weight: 700;
                        font-family: $font-main;
                    } //
                }
                // text-transform: uppercase;
            }
        }
    }
    .black {
        background: $color-darkgray;
    }
    .purple {
        background: $color-purple;
    }
    .blue {
        background: $color-torq;
    }
    .dark-purple {
        background: $color-darkpurple;
    }
}