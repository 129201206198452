$br-1: 10px;
$br-2: 12px;
$br-3: 18px;
$br-lg: 50px;
$br-md: 20px;
$br-sm: 10px;
$color-smoke: #F0F0F0;
$color-predimwhite: #E8E8E8;
$color-darkgray: #2F2F2F;
$color-pink: #D000FF;
$color-normal-pink: #E791FB;
$color-gray: #4f4f4f;
$color-lightgray: #9E9E9E;
$color-purple: #790071;
$color-white: #FFFFFF;
$color-darkpurple: #560B72;
$color-red: #FF0000;
$color-torq: #006579;
$color-blue: #00B7FF;
$color-palepink: #E0C7E0;
$color-yellow: #EFF6A7;
$color-green: #B1E8B5;
$color-dim: #0000004D;
$color-black: #000000;
$color-dimwhite: #FAFAFA;
@font-face {
    font-family: "Grotesk";
    src: url("../Soyuz Grotesk/SoyuzGroteskBold.ttf");
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$font: "Grotesk";
$font-main: "Montserrat Alternates",
sans-serif;