@import "../../styles/variables.scss";
.main {
    width: 100%;
    .main-wrapper {
        width: calc(100% - 4vw);
        display: flex;
        flex-direction: column;
        align-items: center;
        .topics {
            width: calc(100% - 4vw);
            font-weight: 300;
            margin-top: 1em;
            // margin-bottom: 3vw;
            display: flex;
            justify-content: center;
            height: 26vw;
            .topics-img {
                // width: auto;
                // height: 15vw;
                // min-height: 100px;
                height: 26vw;
                img {
                    // height: 15vw;
                    // min-height: 100px;
                    // width: 60vw;
                    height: 26vw;
                    border-radius: $br-lg 0 0 $br-lg;
                }
            }
            .topics-text {
                width: 55%;
                max-width: 600px;
                position: relative;
                border-radius: $br-lg;
                background-color: $color-white;
                // height: 15vw;
                // min-height: 100px;
                // width: 55vw;
                // min-width: 200px;
                padding: 1em 1em;
                margin-left: -3em;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .topics-text-h2,
                .topics-text-h2-price {
                    text-align: center;
                    font-size: 6vw;
                    @media (min-width: 1700px) {
                        font-size: 100px;
                    }
                    line-height: 80%;
                    font-weight: 300;
                    margin: 0;
                    @media(min-width: 700px) {
                        margin-top: 1em;
                    }
                    // margin-bottom: 0%;
                }
                .topics-text-p {
                    text-align: center;
                    // height: 4vw;
                    font-size: 20px;
                    line-height: 80%;
                    margin: 0;
                    // margin: 1em;
                }
                .topics-text-link {
                    display: flex;
                    justify-content: right;
                    align-items: flex-end;
                    flex-direction: row;
                    align-items: center;
                    .topics-text-link-arrow {
                        margin-left: 0.3em;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                    }
                    .topics-text-link-btn-direct,
                    .topics-text-link-btn-places,
                    .topics-text-link-btn-teachers,
                    .topics-text-link-btn-price {
                        text-decoration: none;
                        color: $color-darkgray;
                        padding: 2.5px 20px;
                        background-color: $color-palepink;
                        border-radius: $br-md;
                        font-size: 20px;
                        // margin: 1em;
                        border: 0px;
                        margin-bottom: 0;
                        font-size: medium;
                        font-weight: 700;
                        font-family: $font-main;
                    }
                    .topics-text-link-btn-places,
                    .topics-text-link-btn-price {
                        background-color: $color-green;
                    }
                    .topics-text-link-btn-teachers {
                        background-color: $color-yellow;
                    }
                }
            }
        }
        .topics {
            @media (max-width: 700px) {
                display: flex;
                flex-direction: column;
                height: unset;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                .topics-img {
                    width: calc(100% - 4vw);
                    height: unset;
                    border-radius: 0;
                    img {
                        height: unset;
                        width: calc(100%);
                        border-radius: $br-lg $br-lg 0 0;
                    }
                }
                .topics-text {
                    width: calc(100% - 4vw - 2em);
                    margin: 0;
                    margin-top: -3em;
                    max-width: unset;
                    .topics-text-h2,
                    .topics-text-h2-price {
                        font-size: 12vw;
                        margin-bottom: 0.3em;
                    }
                    .topics-text-p {
                        margin: 1em 0em;
                    }
                }
            }
        }
    }
}