    .locations {
        width: calc(100% - 5vw);
        max-width: 1000px;
        color: #333;
    }
    
    .desktop {
        display: none;
    }
    
    @media( min-width: 1000px) {
        .locations {
            display: none;
        }
        .desktop {
            width: calc(100% - 5vw);
            display: flex;
            max-width: unset;
        }
    }