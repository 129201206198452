.app {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.landing {
    background: #F0F0F0;
    min-height: 100vh;
}

.container {
    margin: 1em 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: max(1400px, 96vw);
    width: 90vw;
}