.main {
    .main-wrapper {
        width: 99vw;
        .news-header {
            text-align: center;
            padding-left: 30%;
            padding-right: 30%;
        }
        .news-list {
            // width: 99vw;
            display: flex;
            justify-content: left;
            overflow-x: scroll;
            .news-list-1 {
                border-radius: 60px;
                background-color: white;
                width: 40vw;
                margin-left: 20px;
                padding-bottom: 20px;
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 20px;
                img {
                    width: calc(40vw - 40px)
                }
                h2 {
                    text-align: center;
                }
            }
        }
    }
}