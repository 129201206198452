@import "../../styles/variables.scss";
.footer {
    width: 100%;
    margin-bottom: 1em;
    .footer-wrapper {
        a {
            // font-size: larger;
            text-decoration: none;
            color: $color-white;
        }
        margin-left: 2vw;
        width: calc(100% - 4vw);
        background-color: $color-pink;
        border-radius: $br-md;
        .footer-header {
            width: calc(100% - 4vw);
            padding-top: 2vw;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: $color-white;
            .footer-logo {
                margin-left: 3vw;
                width: 6vw;
                height: 80px;
                width: 80px;
                // @media (max-width: 900px) {
                //     width: 60px;
                //     min-width: none;
                //     height: auto;
                // }
                // @media (min-width: 900px) {
                //     min-width: 100px;
                // }
            }
            .footer-phone {
                font-size: larger;
                @media (max-width: 500px) {
                    font-size: medium;
                }
                // @media (max-width: 450px) {
                //     font-size: small;
                // }
            }
            .footer-mess {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                align-items: center;
                width: 30vw;
                margin-right: 3vw;
                a {
                    cursor: pointer;
                }
                .footer-mess-img {
                    height: 8vw;
                    max-height: 40px;
                    // width: 80px;
                    cursor: pointer;
                }
            }
        }
        .footer-body {
            padding-bottom: 2vw;
            display: flex;
            justify-content: space-between;
            width: calc(60% - 4vw);
            @media (min-width: 470px) {
                min-width: 400px;
            }
            @media (max-width: 470px) {
                width: 300px
            }
            margin-left: 3vw;
            padding-right: 4vw;
            .footer-body-left,
            .footer-body-right {
                display: flex;
                flex-direction: column;
                justify-content: start;
            }
            .footer-body-h2 {
                color: $color-predimwhite;
                font-size: 15px;
            }
            .footer-body-p {
                color: $color-gray;
                font-size: 15px;
            }
            .footer-body-p:hover {
                color: $color-white;
                cursor: pointer;
                font-size: 15px;
            }
        }
    }
    .purple {
        background-color: $color-purple;
        .footer-body {
            .footer-body-h2 {
                font-size: 15px;
            }
            .footer-body-p {
                color: $color-lightgray;
                font-size: 15px;
            }
        }
    }
    .black {
        background-color: $color-darkgray;
        .footer-body {
            .footer-body-h2 {
                font-size: 15px;
            }
            .footer-body-p {
                color: $color-lightgray;
                font-size: 15px;
            }
        }
    }
    .blue {
        background-color: $color-torq;
        .footer-body {
            .footer-body-h2 {
                font-size: 15px;
            }
            .footer-body-p {
                color: $color-lightgray;
                font-size: 15px;
            }
        }
    }
    .dark-purple {
        background-color: #560B72;
        .footer-body {
            .footer-body-h2 {
                font-size: 15px;
            }
            .footer-body-p {
                color: $color-lightgray;
                font-size: 15px;
            }
        }
    }
}