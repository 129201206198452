@import "../../styles/variables.scss";
.reviews {
    display: flex;
    .reviews-wrapper {
        width: calc(100% - 4vw);
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
            margin: 0;
            font-size: 6vw;
        }
        @media (max-width: 1000px) {
            h1 {
                margin: 0.3em 0;
                font-size: 12vw;
            }
        }
        p {
            font-size: medium;
            font-weight: 700;
            font-family: $font-main;
        }
        .review {
            width: calc(100% - 4vw);
            transition: 1s ease-in-out;
            // height: 50vw;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .review-from {
                height: 55px;
                display: flex;
                flex-direction: row;
                img {
                    width: 55px;
                    height: 55px;
                    margin-right: 0.5em;
                }
            }
            padding: 1em 1.5em;
            border-radius: $br-lg;
            background-color: #D9D9D9;
            .arrows {
                width: calc(100% - 4vw);
                display: flex;
                justify-content: end;
                cursor: pointer;
            }
        }
    }
}