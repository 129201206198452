 @import "../../styles/variables.scss";
 .openset {
     // width: 100%;
     .openset-wrapper {
         width: 100%;
         background-image: url("../../images/landing-preview.png");
         background-position-x: right;
         background-size: contain;
         background-repeat: no-repeat;
         user-select: none;
         height: 40vw;
         a {
             color: $color-white;
         }
         .mobile {
             display: none;
         }
         .desktop {
             display: block;
             h1 {
                 font-weight: 300;
                 margin: 0;
                 line-height: 80%;
                 font-size: 6vw;
             }
             p {
                 font-size: 1.9vw;
             }
             .button {
                 background: #E791FB;
                 width: fit-content;
                 padding: 0.3em 0.8em;
                 margin-top: 0;
                 border-radius: 30px;
                 cursor: pointer;
                 font-weight: 700;
                 font-family: $font-main;
                 transition: 0.3s ease-in-out;
             }
             .button:hover {
                 transition: 0.3s ease-in-out;
                 color: $color-white;
                 padding: 0.3em 1em;
             }
         }
         @media (max-width: 700px) {
             background-image: url("../../images/land-mobile.png");
             background-position-x: right;
             background-size: contain;
             background-repeat: no-repeat;
             height: 95vw;
             // width: calc(100% - 4vw);
             .desktop {
                 display: none;
             }
             .mobile {
                 // display: flex;
                 display: flex;
                 flex-direction: column;
                 h1 {
                     // position: relative;
                     font-weight: 300;
                     margin-top: 54vw;
                     line-height: 80%;
                     font-size: 10vw;
                 }
                 p {
                     font-size: 4vw;
                     margin-top: 0;
                     background: #E791FB;
                     width: fit-content;
                     padding: 0.3em 0.8em;
                     border-radius: 30px;
                     cursor: pointer;
                     transition: 0.3s ease-in-out;
                 }
                 p:hover {
                     transition: 0.3s ease-in-out;
                     color: $color-white;
                     padding: 0.3em 1em;
                 }
             }
         }
     }
 }