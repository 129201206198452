@import "../../styles/variables.scss";
.featured {
    user-select: none;
    .featured-wrapper {
        width: 100%;
        .row-featured {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 1em;
        }
        .swiper-pagination {
            margin-top: 1em;
        }
        .cell-featured {
            background: $color-dimwhite;
            border-radius: $br-lg;
            width: 40vw;
            img {
                width: 100%;
                border-radius: $br-lg;
            }
            p {
                width: 100%;
                text-align: center;
                margin: 0;
                font-size: 2vw;
                height: 6vw;
            }
        }
        .swiper {
            display: none;
        }
        @media (max-width: 700px) {
            .swiper {
                display: flex;
                border-radius: $br-lg;
                height: 55vw;
                width: calc(100% - 2vw);
                .cell-featured {
                    width: 100%;
                    // height: 55vw;
                    height: 55vw;
                    border-radius: $br-lg;
                }
                p {
                    width: 100%;
                    text-align: center;
                    margin: 0;
                    font-size: 4vw;
                    height: 6vw;
                    margin-bottom: 0.5em;
                }
            }
            .desktop {
                display: none;
            }
        }
    }
}