.history {
    .history-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .mobile {
            display: none;
        }
        h1 {
            margin: 0;
            font-weight: 300;
            font-size: 6vw;
            text-align: center;
        }
        @media (max-width: 1000px) {
            .mobile {
                display: flex;
            }
            h1 {
                font-size: 12vw;
            }
        }
    }
}