    @import "../../styles/variables.scss";
    .time {
        // color: #333;
        .time-wrapper {
            width: 96vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            h1,
            h2,
            h3,
            p {
                font-weight: 700;
                font-family: $font-main;
            }
            h3 {
                margin: 0;
                font-size: 20px;
                font-weight: 900;
            }
            p {
                font-size: 20px;
            }
            .time-h1 {
                text-align: center;
                margin-bottom: 0px;
                font-size: 35px;
                color: $color-black;
            }
            .time-h1-div {
                display: flex;
                justify-content: space-around;
                margin-bottom: 30px;
                .time-h1-divv {
                    width: fit-content;
                    padding-right: 2vw;
                    padding-left: 2vw;
                    background-color: $color-red;
                    border-radius: 15px;
                    color: $color-white;
                    font-size: 12px;
                }
            }
            .time-p-bold {
                text-align: center;
                font-weight: 800;
                margin-bottom: 0px;
                font-size: 13px;
            }
            .time-p {
                text-align: center;
                font-weight: 600;
                margin-bottom: 20px;
                font-size: 13px;
                margin-top: 2px;
            }
            .time-h2 {
                color: $color-blue;
                text-align: center;
                font-family: $font-main;
                font-size: 13px;
                margin-bottom: 10px;
            }
            .time-h2-spain {
                color: $color-purple;
                text-align: center;
                font-size: 13px;
                font-weight: 800;
                margin-top: 10px;
            }
            .div-for-margin {
                margin-bottom: 10vw;
            }
            .slogan {
                padding: 0em 0;
                margin-bottom: 1em;
                font-family: $font;
                color: $color-darkgray;
                font-size: max(5vw, 40px);
            }
            .p-price {
                font-weight: 600;
                text-align: center;
                font-size: 13px;
                margin-top: 5px;
                margin-bottom: 5px;
            }
            .p-price-bold {
                font-weight: 700;
                text-align: center;
                font-size: 13px;
                margin: 0;
            }
            .h1-price {
                color: $color-torq;
                text-align: center;
                font-size: 35px;
                margin-bottom: 5px;
            }
            .h3-price-bold {
                text-align: center;
                font-size: 17px;
                font-weight: 800;
            }
            .ital {
                font-style: italic;
                font-weight: 600;
                font-family: $font-main;
                margin-bottom: 0;
            }
            .marg {
                margin-bottom: 15px;
            }
            .h2-price {
                margin-top: 0px;
                margin-bottom: 5px;
                font-weight: 800;
                font-size: 30px;
                color: $color-black;
            }
            .marg2x {
                margin-bottom: 45px;
            }
        }
    }